<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-page-testecog">
          <b-row align-v="center">
            <b-col sm="1">
              <div class="icone-header-page">
                <img src="/assets/images/modal_icons/2_teste_cognitivos.png" />
              </div>
            </b-col>

            <b-col>
              <h1 class="titulo-header-page text-white">
                Novo Teste Cognitivo
              </h1>
              <h4 class="text-white">Inicie ou marque um Teste Cognitivo:</h4>
            </b-col>
            <b-button
              v-ripple.400="'rgba(0, 0, 0, 0)'"
              variant="outline-secondary"
              class="button-right ml-1 botao-white-outline"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              <span class="align-middle">Excluir</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(0, 0, 0, 0)'"
              variant="outline-primary"
              class="button-right ml-1 botao-white-preenchido"
              @click="update()"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Concluir Consulta</span>
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col>
            <b-card>
              <b-row align-v="end">
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Nome do Teste"
                    rules="required"
                  >
                    <b-form-group class="mb-2 mt-2" label="Nome do Teste">
                      <b-form-input
                        v-model="consulta.appCognitiveTests.name"
                        placeholder="Digite aqui"
                      />
                    </b-form-group>
                    <small class="text-danger d-block mb-2">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo do Teste"
                    rules="required"
                  >
                    <b-form-group class="mb-2 mt-2" label="Tipo do Teste">
                      <b-form-input
                        v-model="consulta.appCognitiveTests.type"
                        placeholder="Digite aqui"
                      />
                    </b-form-group>
                    <small class="text-danger d-block mb-2">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3">
                  <b-form-group class="mb-2 mt-2" label="Duração da Passação">
                    <validation-provider
                      #default="{ errors }"
                      name="Horas"
                      rules="required"
                    >
                      <b-form-input
                        v-model="consulta.appCognitiveTests.hour"
                        class="mb-2"
                        type="number"
                        placeholder="HH"
                      />
                      <small class="text-danger d-block mb-2">{{
                        errors[0]
                      }}</small>
                    </validation-provider>
                    <validation-provider
                      #default="{ errors }"
                      name="Minutos"
                      rules="required"
                    >
                      <b-form-input
                        v-model="consulta.appCognitiveTests.minute"
                        type="number"
                        placeholder="MM"
                      />

                      <small class="text-danger d-block mb-2">{{
                        errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Resultado"
                    rules="required"
                  >
                    <b-form-group class="mb-2 mt-2" label="Resultados do Teste">
                      <b-form-textarea
                        v-model="consulta.appCognitiveTests.testResult"
                        id="textarea-default"
                        placeholder="Inserir Detalhes"
                        rows="5"
                      />
                    </b-form-group>

                    <small class="text-danger d-block mb-2">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-col>

                <b-row class="col-12 m-0">
                  <b-col col="12" class="p-0">
                    <h2 class="mb-2">
                      <feather-icon
                        icon="CheckCircleIcon"
                        size="30"
                        class="mr-50"
                      />
                      Anexos
                    </h2>

                    <DragAndDropComponent />
                  </b-col>
                </b-row>
              </b-row>
              <b-row class="button-right">
                <b-button
                  align="right"
                  class="m-1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Excluir
                </b-button>

                <b-button
                  class="m-1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  :to="'/consulta/' + consulta.id"
                >
                  Voltar para a consulta
                </b-button>
                <b-button
                  class="m-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="update()"
                >
                  Salvar
                </b-button>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import {
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BMedia,
  BButton,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import { BFormTextarea, BCardText, BModal, VBModal } from 'bootstrap-vue'
import { BTabs, BTab } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import DragAndDropComponent from '@/components/input/DragAndDrop.vue'
import medicalAppointmentService from '@/services/medicalAppointmentService'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { hideSpinner, showSpinner } from '@/services/spinService'
import utilsService from '@/services/utilsService'
import { getOneStatusPersonByName } from '@/@core/constants/personStatus'
import authService from '@/services/authService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'

export default {
  components: {
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCardText,
    BFormTextarea,
    BTabs,
    BCardText,
    BTab,
    BButton,
    AppTimeline,
    AppTimelineItem,
    BImg,
    BMedia,
    BAvatar,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    BModal,
    DragAndDropComponent,
    ValidationProvider,
    ValidationObserver,
  },

  props: ['idConsulta'],
  data: () => ({
    files: [],
    consulta: {
      appCognitiveTests: {},
      createdBy: null,
      createdAt: null,
      updatedBy: null,
      updatedAt: null,
      id: null,
      startDate: null,
      endDate: null,
      location: null,
      comments: null,
      status: null,
      number: null,
      patient: {
        createdBy: null,
        createdAt: null,
        updatedBy: null,
        updatedAt: null,
        id: null,
        age: null,
        birthDate: null,
        gender: null,
        nationality: null,
        occupation: null,
        maritalStatus: null,
        schooling: null,
        visitedPsy: true,
        outpatient: true,
        internment: true,
        note: null,
        parentNote: null,
        nationalHealthRegistry: null,
        physicalPerson: {
          createdBy: null,
          createdAt: null,
          updatedBy: null,
          updatedAt: null,
          id: null,
          cpf: null,
          professionalBoardNumber: null,
          firstName: null,
          lastName: null,
          person: {
            createdBy: null,
            createdAt: null,
            updatedBy: null,
            updatedAt: null,
            id: null,
            address: null,
            neighborhood: null,
            city: null,
            state: null,
            postalCode: null,
            phoneNumber: null,
            mobilePhone: null,
            email: null,
            status: null,
            login: null,
          },
        },
        dependents: null,
        parents: null,
      },
      user: {
        createdBy: null,
        createdAt: null,
        updatedBy: null,
        updatedAt: null,
        id: null,
        login: null,
        password: null,
        status: null,
        firstName: null,
        lastName: null,
        email: null,
        authorities: [],
        fullName: null,
      },
      initialConsultation: {},
      appCognitiveTests: null,
      consultationTypes: [],
    },
    sexos: ['Masculino', 'Feminino', 'Outros'],
    escolaridades: [
      'Ensino médio incompleto',
      'Ensino médio completo',
      'Ensino superior incompleto',
      'Ensino superior completo',
    ],
    meses: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    form: {
      nome: undefined,
    },
  }),
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  computed: {
    dias() {
      return Object.keys(new Array(31).fill(0)).map(i => parseInt(i) + 1)
    },
    anos() {
      return Object.keys(new Array(150).fill(0)).map(i => parseInt(i) + 1852)
    },
  },
  methods: {
    ...utilsService,
    onSubmit() {
      alert('Formulário Enviado')
    },
    async getConsulta() {
      await medicalAppointmentService.getById(this.idConsulta).then(res => {
        if (!res.initialConsultation) {
          res.initialConsultation = {}
        }
        if (!res.appCognitiveTests) {
          res.appCognitiveTests = {}
        }
        res.patient.physicalPerson.person.status = getOneStatusPersonByName(
          res.patient.physicalPerson.person.status
        )
        this.consulta = res
      })
    },
    prepareRequest(status) {
      let consultaJSON = JSON.parse(JSON.stringify(this.consulta))
      consultaJSON.patient.physicalPerson.person.status =
        consultaJSON.patient.physicalPerson.person.status.name
      consultaJSON = {
        ...consultaJSON,
        status,
        login: authService.getLogin(),
        patientId: consultaJSON.patient.id,
        consultationTypeIds: consultaJSON.consultationTypes.map(
          type => type.id
        ),
        consultations: consultaJSON.consultationTypes.map(consultation =>
          this.getConsultations(consultation.id)
        ),
      }
      return consultaJSON
    },

    async update(status) {
      if (await this.$refs.refFormObserver.validate()) {
        medicalAppointmentService
          .update(this.prepareRequest(status))
          .then(async res => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (this.files.length) {
              await this.saveFiles()
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            console.log('fechou')
            hideSpinner()
            this.$router.push('/consulta/' + this.consulta.id)
          })
        showSpinner()
      }
    },
    async saveFiles() {
      showSpinner()
      return medicalAppointmentService
        .update(this.files)
        .then(res => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Dados salvos com sucesso!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push('/consulta/' + this.idConsulta)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          console.log('fechou')
          hideSpinner()
        })
    },
    hasTypeByLabel(label) {
      return this.consulta.consultationTypes.some(type =>
        type.name.includes(label)
      )
    },
    getTypeIdConsulta() {
      if (!this.consulta) return ''
      if (this.isConsultaInicial()) return 1
      if (this.isConsultaFinal()) return 6
      if (this.isConsultaRegular()) return 5
      return 0
    },
    getTypeConsulta() {
      if (!this.consulta) return ''
      if (this.isConsultaInicial()) return 'Inicial'
      if (this.isConsultaFinal()) return 'Final'
      if (this.isConsultaRegular()) return 'Regular'
      return ''
    },
    getClassByType() {
      switch (this.getTypeConsulta()) {
        case 'Inicial':
          return 'c-consulta-inicial'
        case 'Final':
          return 'c-devolutiva'
        case 'Regular':
          return 'c-consulta-com-apl'
        default:
          return ''
      }
    },
    getUrlImgByType() {
      switch (this.getTypeConsulta()) {
        case 'Inicial':
          return '1_consulta_inicial.png'
        case 'Final':
          return '4_devolutiva.png'
        case 'Regular':
          return '1_consulta_inicial.png'
        default:
          return ''
      }
    },
  },
  async mounted() {
    if (this.idConsulta) {
      await this.getConsulta()
    }
  },
}
</script>

