var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-card',{staticClass:"header-page-testecog"},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"sm":"1"}},[_c('div',{staticClass:"icone-header-page"},[_c('img',{attrs:{"src":"/assets/images/modal_icons/2_teste_cognitivos.png"}})])]),_c('b-col',[_c('h1',{staticClass:"titulo-header-page text-white"},[_vm._v(" Novo Teste Cognitivo ")]),_c('h4',{staticClass:"text-white"},[_vm._v("Inicie ou marque um Teste Cognitivo:")])]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(0, 0, 0, 0)'),expression:"'rgba(0, 0, 0, 0)'",modifiers:{"400":true}}],staticClass:"button-right ml-1 botao-white-outline",attrs:{"variant":"outline-secondary"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Excluir")])],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(0, 0, 0, 0)'),expression:"'rgba(0, 0, 0, 0)'",modifiers:{"400":true}}],staticClass:"button-right ml-1 botao-white-preenchido",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.update()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Concluir Consulta")])],1)],1)],1)],1)],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',[_c('b-card',[_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Nome do Teste","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2 mt-2",attrs:{"label":"Nome do Teste"}},[_c('b-form-input',{attrs:{"placeholder":"Digite aqui"},model:{value:(_vm.consulta.appCognitiveTests.name),callback:function ($$v) {_vm.$set(_vm.consulta.appCognitiveTests, "name", $$v)},expression:"consulta.appCognitiveTests.name"}})],1),_c('small',{staticClass:"text-danger d-block mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Tipo do Teste","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2 mt-2",attrs:{"label":"Tipo do Teste"}},[_c('b-form-input',{attrs:{"placeholder":"Digite aqui"},model:{value:(_vm.consulta.appCognitiveTests.type),callback:function ($$v) {_vm.$set(_vm.consulta.appCognitiveTests, "type", $$v)},expression:"consulta.appCognitiveTests.type"}})],1),_c('small',{staticClass:"text-danger d-block mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{staticClass:"mb-2 mt-2",attrs:{"label":"Duração da Passação"}},[_c('validation-provider',{attrs:{"name":"Horas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-2",attrs:{"type":"number","placeholder":"HH"},model:{value:(_vm.consulta.appCognitiveTests.hour),callback:function ($$v) {_vm.$set(_vm.consulta.appCognitiveTests, "hour", $$v)},expression:"consulta.appCognitiveTests.hour"}}),_c('small',{staticClass:"text-danger d-block mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Minutos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"MM"},model:{value:(_vm.consulta.appCognitiveTests.minute),callback:function ($$v) {_vm.$set(_vm.consulta.appCognitiveTests, "minute", $$v)},expression:"consulta.appCognitiveTests.minute"}}),_c('small',{staticClass:"text-danger d-block mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"9"}},[_c('validation-provider',{attrs:{"name":"Resultado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2 mt-2",attrs:{"label":"Resultados do Teste"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Inserir Detalhes","rows":"5"},model:{value:(_vm.consulta.appCognitiveTests.testResult),callback:function ($$v) {_vm.$set(_vm.consulta.appCognitiveTests, "testResult", $$v)},expression:"consulta.appCognitiveTests.testResult"}})],1),_c('small',{staticClass:"text-danger d-block mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',{staticClass:"col-12 m-0"},[_c('b-col',{staticClass:"p-0",attrs:{"col":"12"}},[_c('h2',{staticClass:"mb-2"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckCircleIcon","size":"30"}}),_vm._v(" Anexos ")],1),_c('DragAndDropComponent')],1)],1)],1),_c('b-row',{staticClass:"button-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"m-1",attrs:{"align":"right","variant":"outline-primary"}},[_vm._v(" Excluir ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"m-1",attrs:{"variant":"outline-primary","to":'/consulta/' + _vm.consulta.id}},[_vm._v(" Voltar para a consulta ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"m-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }